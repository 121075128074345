import { MongoQueryParser, NowReference, OrderBySelector, QueryPredicate } from "./MongoQueryParser";
import { ICaseOption, IWhereQueryOption } from "jsstore";
import type { IEntity } from "@core/Models/i-entity";
import _ from "lodash";
import { SearchTools } from "./SearchTools";
import Logger from "js-logger";

export type OrderSpecification = string | {
        [columnName: string]: [ICaseOption];
    };

export type WhereSpecification = any;

export interface IQuerySpecificatoin {
    where: WhereSpecification;
    fullTextSearch?: string;
    orderBy: string | null | {
        [columnName: string]: [ICaseOption];
    };
    transient?: boolean;
}

export class LiveQuery {
    wherePredicate: QueryPredicate = () => [true, Infinity];
    orderBySelector: OrderBySelector = (entity) => entity.id;

    nowRef: NowReference = new NowReference();

    mongoQuery: any;
    fullTextSearch: string | undefined = undefined;
    orderBy?: OrderSpecification;

    public static fingerprint(query: IQuerySpecificatoin) {
        return JSON.stringify(query);
    }

    public static fromMongoQuery(query: IQuerySpecificatoin, queryVars: Map<string, any>) {
        const result = new LiveQuery();

        result.mongoQuery = query.where;
        result.fullTextSearch = query.fullTextSearch;

        let mainPredicate: QueryPredicate = () => [true, Infinity];
        if (query.where) {
            let parser = new MongoQueryParser(true, queryVars);

            mainPredicate = parser.find(_.cloneDeep(query.where), result.nowRef);
        }


        if (query.fullTextSearch) {
            let textToSearch = query.fullTextSearch.trim().toLowerCase();
            const normalizedTextToSearch = SearchTools.normalizeTextToSearch(textToSearch);

            let fullTextSearchPredicate = (entity: IEntity) => {
                try {
                    return SearchTools.isValuesInEntity(normalizedTextToSearch, entity);
                }
                catch (error) {
                    Logger.error(`[LiveQuery] fullTextSearchPredicate: Error: ${(error as Error).message}`);
                    return false;
                }
            };

            result.wherePredicate = (entity: IEntity) => {
                if (!fullTextSearchPredicate(entity))
                    return [false, Infinity];

                else
                    return mainPredicate(LiveQuery.entity2queryable(entity));
            };
        }
        else {
            result.wherePredicate = (entity: IEntity) => mainPredicate(LiveQuery.entity2queryable(entity));
        }

        if (query.orderBy != null) {

            //todo: hacky workaround for ipku sorting. need to be replaced
            if (query.orderBy === '$ipku_tasks') {
                result.orderBySelector = (entity) => {
                    try {
                        const maxTime = '9999-99-99T99:99';
                        const queryable = LiveQuery.entity2queryable(entity);
                        if (queryable.tasks == null || queryable.tasks.length == 0)
                            return maxTime;

                        const undoneTasks = queryable.tasks.filter((x:any) => !x.completed && x.deadline != null);
                        if (undoneTasks.length == 0)
                            return maxTime;

                        const dates = undoneTasks.map( (x:any)=> new Date(x.deadline*1000).toISOString().slice(0, 10) + 'T' + x.taskTime?.toString() );

                        const minimum = dates.reduce((a:any, b:any) => a < b ? a : b);
                        
                        return minimum;
                    } catch (err) {
                        return "";
                    }
                }
            }
            else if (typeof query.orderBy === 'string')
                result.orderBySelector = (entity) => LiveQuery.lowerString(LiveQuery.entity2queryable(entity)[query.orderBy as string]);
            else {
                let columns = Object.keys(query.orderBy)
                result.orderBySelector = (entity) => {
                    const queryable = LiveQuery.entity2queryable(entity);
                    columns.map(c => LiveQuery.lowerString(queryable[c]))
                }
            }
        } else {
            result.orderBySelector = (entity) => entity.id;
        }

        return result;
    }

    private static lowerString(value: any): string {
        if (typeof value === 'string')
            return value.trim().toLocaleLowerCase();
        else
            return value;
    }

    private static entity2queryable(entity: IEntity): Record<string, any> {
        return {id:entity.id, _lastEventNumber: entity._lastEventNumber ?? Infinity, ...entity.data};
    }
}


export function filterArrByScript(values: IEntity[], wherePairs: [string, (IWhereQueryOption | string | number | boolean)][], fullTextSearch?: string): string[] {
    let result = values;
    for(let wherePair of wherePairs ?? []){
        result = filterByScript(result, wherePair);
    }

    if (fullTextSearch) {
        const textToSearch = fullTextSearch.trim().toLowerCase();
        result = result.filter(x => JSON.stringify(x).toLowerCase().includes(textToSearch) )
    }

    return result.map(x => x.id);
}

function filterByScript(values: IEntity[], wherePair: [string, (IWhereQueryOption | string | number | boolean)]): IEntity[] {
    const [fieldName, whereClause] = wherePair;
    if(_.isString(whereClause) || _.isNumber(whereClause) || _.isBoolean(whereClause)) {
        return values.filter(v => stringEqualsIgnoreCase(v.data[fieldName], whereClause));
    }

    const queryOption = Object.entries(whereClause as IWhereQueryOption)[0];
    const [queryOptionKey, queryOptionValue] = queryOption;

    switch (queryOptionKey){
        case '>':
            return values.filter(x=>x.data[fieldName] as any > queryOptionValue)
            break;
        case '<':
            return values.filter(x=>x.data[fieldName] as any < queryOptionValue)
            throw new Error('not implemented');
            break;
        case '>=':
            return values.filter(x=>x.data[fieldName] as any >= queryOptionValue)
            break;
        case '<=':
            return values.filter(x=>x.data[fieldName] as any <= queryOptionValue)
            break;
        case '!=':
            if (queryOptionValue === null)
                return values.filter(x=>x.data[fieldName] !== null && x.data[fieldName] !== undefined);
            else
                return values.filter(x=>x.data[fieldName] !== queryOptionValue);
            break;
        case '-':
            const low = queryOptionValue.low;
            const high = queryOptionValue.high;

            return values.filter(x=> x.data[fieldName] as any >= low && x.data[fieldName] as any <= high);
        case 'like':
            const substring = (queryOptionValue?.toString() ?? "").toLowerCase();
            return values.filter(x => (x.data[fieldName] as any ?? "").toString().toLowerCase().includes(substring));
        case 'regex':
            debugger;
            throw new Error('not implemented');
            break;
        case 'or':
            debugger;
            throw new Error('not implemented');
            break;
        case 'in':
            const inVals = queryOptionValue as any[];

            return values.filter(x=> inVals.some(y=>stringEqualsIgnoreCase(x.data[fieldName], y)));
        default:
            throw new Error('not implemented');
    }
}

function stringEqualsIgnoreCase(a: any, b: any):boolean {
    return (a?.toString() ?? "").toLowerCase() === (b?.toString() ?? "").toLowerCase();
}